import React from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';

const TransitionComponent = ({ children }) => {
  const location = useLocation();

  return (
    <SwitchTransition>
      <Transition
        key={location.pathname}
        timeout={500}
        onEnter={(node) => {
          gsap.set(node, { autoAlpha: 0, scale: 0.8, y: 0, ease: 'power3.inOut'});
          gsap
            .timeline({ paused: true })
            .to(node, { autoAlpha: 1, y: 50, duration: 1, ease: 'power3.inOut' })
            .to(node, { scale: 1, duration: 0.5 })
            .play();
        }}
        onExit={(node) => {
          gsap
            .timeline({ paused: true })
            .to(node, { autoAlpha: 0, scale: 0.8, duration: 0.5, y: 0 })
            .to(node, { autoAlpha: 0, duration: 0.5, y: 50, ease: 'power3.inOut' })
            .play();
        }}
      >
        {children}
      </Transition>
    </SwitchTransition>
  );
};

export default TransitionComponent;
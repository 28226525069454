import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Collapsible from 'react-collapsible'

// Components
// import ProjectsMenu from '../../components/ProjectsMenu';
import ScrollTop from '../../components/ScrollTop';

// Stylesheet
import '../../css/projects.css'

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

// Photoshop Project Images
import psImg1 from '../../images/otherworks/PS_img1.png';
import psImg2 from '../../images/otherworks/PS_img2.png';

// Patterns Project Images
import usPattern from '../../images/otherworks/UnstructuredPattern.png';
import sPattern from '../../images/otherworks/StructuredPattern.png';

// Visual Arts Project Images
import vsImg1 from '../../images/otherworks/VisualArts_img1.png';

function OtherWorks() {
    return (
        <div>
            {/* <ProjectsMenu /> */}
                <div className="OtherWorks">
                    <Collapsible trigger={["Digital Art", <FontAwesomeIcon icon={faChevronUp} className="projectChevron" alt="Reveal project"/>]} >
                        <div className='photoShop'>
                            <Container>
                                <Row>
                                    <h1>Photoshop</h1>
                                    <Col md={6}>
                                        <img className="psImg1" src={psImg1} alt="Photobash project depicting a childhood story called 'Taro the Dragon Boy'" />
                                        <p>Taro the Dragon Boy</p>
                                    </Col>
                                    <Col md={6}>
                                        <img className="psImg2" src={psImg2} alt="Fake advert for Airbnb" />
                                        <p>Airbnb Mock Advert</p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="pattern">
                            <Container>
                                <Row>
                                    <h1>Unstructured Vs. Structured Pattern</h1>
                                    <Col md={6}>
                                        <img className="usPattern" src={usPattern} alt="Unstructured pattern featuring my dog and cat" />
                                        <p>Unstructured Pattern ft. Tazu, Ace, and some of their favourites</p>
                                    </Col>
                                    <Col md={6}>
                                        <img className="sPattern" src={sPattern} alt="Structured pattern featuring a frog and a swan" />
                                        <p>Structured Pattern with a Swan and a Frog</p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Collapsible>
                    <br></br>
                    <Collapsible trigger={["Visual Art", <FontAwesomeIcon icon={faChevronUp} className="projectChevron" alt="Reveal project"/>]} >
                        <div className="visArt">
                            <Container>
                                <Row>
                                    <h1>Visual Art</h1>
                                    <Col md={12}>
                                        <img className="vsImg1" src={vsImg1} alt="A few of my visual art works ranging in mediums from paper and pencil to acrylic on canvas" />
                                        <p>A few of my visual art works ranging in mediums from paper and pencil to acrylic on canvas</p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Collapsible>
                </div>
            <ScrollTop />
        </div>
    );
}

export default OtherWorks; 
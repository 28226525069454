import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Collapsible from 'react-collapsible'

// Components
// import ProjectsMenu from '../../components/ProjectsMenu';
import ScrollTop from '../../components/ScrollTop';

// Stylesheet
import '../../css/projects.css'

// Software Icons
import illustratorIcon from '../../images/icons/illustratorIcon.png';
import xdIcon from '../../images/icons/xdIcon.png';
import indesignIcon from '../../images/icons/indesignIcon.png';
import photoshopIcon from '../../images/icons/photoshopIcon.png';

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

// Northern Xscape Rentals Project Images/Videos
import XscapeHeader from '../../images/uiuxprojects/XscapeHeader.png';
import XscapeProduct from '../../images/uiuxprojects/XscapeProductOverview.png';
import XscapeMapSearch from '../../images/uiuxprojects/XscapeMapSearch.png';
import XscapeCompAnalysis from '../../images/uiuxprojects/XscapeCompAnalysis.png';
import XscapePersona from '../../images/uiuxprojects/XscapePersona.png';
import XscapeStoryboard from '../../images/uiuxprojects/XscapeStoryboard.png';
import XscapeWireframes from '../../images/uiuxprojects/XscapeWireframes.png';
import XscapeHiFi from '../../images/uiuxprojects/XscapeHiFi.png';
import XscapeOnboarding from '../../images/uiuxprojects/XscapeOnboarding.png';
import XscapeLogo from '../../images/uiuxprojects/XscapeLogo.png';

import XscapeDemo1 from '../../videos/XscapeDemo1.mp4';
import XscapeDemo2 from '../../videos/XscapeDemo2.mp4';

// Citra Website Project Images
import CitraWebCover from '../../images/uiuxprojects/CitraWebCover.png';
import CitraWebLogo from '../../images/uiuxprojects/CitraLogo.png';
import CitraHome from '../../images/uiuxprojects/CitraHome.png';
import CitraBrand from '../../images/uiuxprojects/CitraBrand.png';
import CitraColour from '../../images/uiuxprojects/CitraColour.png';
import CitraShop from '../../images/uiuxprojects/CitraShop.png';

function UIUX() {
    return (
        <div>
        {/* <ProjectsMenu /> */}
        <div className='UIUX'>
            <Collapsible trigger={["Northern XScape Rentals", <FontAwesomeIcon icon={faChevronUp} className="projectChevron" alt="Reveal project"/>]}>
                        <div className="northernX">
                            <Container className='header'>
                                <Row className="xscapeHeader">
                                    <Col md={6}>
                                        <img className="xscapeHeaderImg" src={XscapeHeader} alt="Northern Xscape Cover" />
                                    </Col>
                                    <Col md={6}>
                                    <h1>Northern Xscape Rentals</h1>
                                        <p>
                                            A case study for a rental mobile application system for jet-skis and snowmobiles.
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                            <Container className='overview'>
                                <Row>
                                    <Col md={12} className="northernXCol">
                                        <h2 className='sectionTitle'>Overview</h2>
                                        <p className='northernXOverview'>
                                            I was tasked to design a modern digital application for Northern Xscape Rentals, enhancing the rental process, 
                                            alleviating website traffic issues, and providing comprehensive rental information for their Ontario-based jet ski and snowmobile business 
                                            with offices in Haliburton, Huntsville, and Buckhorn.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h3>Tools</h3>
                                            <div className="softwareIcons">
                                                <img src={illustratorIcon} alt="Illustrator icon"/>
                                                <img src={xdIcon} alt="XD icon"/>
                                                <img src={indesignIcon} alt="InDesign icon"/>
                                            </div>
                                    </Col>
                                    <Col md={4}>
                                        <h3>Timeline</h3>
                                        <p className="detailsPara">Sept. - Dec. 2022</p>
                                    </Col>
                                    <Col md={4}>
                                        <h3>Disciplines</h3>
                                            <li className="detailsPara">UX</li>
                                            <li className="detailsPara">Interaction Design</li>
                                            <li className="detailsPara">Visual Design</li>
                                    </Col>
                                </Row>
                                <Row className='northernXDemo1Row'>
                                    <video className='northernXDemo northernXDemo1' autoplay loop="true" controls width="100%">
                                        <source src={XscapeDemo1} title="Xscape app's sign-in and onboarding process demo" type="video/mp4" />
                                    </video>
                                </Row>
                            </Container>
                            <Container className='features'>
                                <Row>
                                    <Col md={4} className="northernXCol">
                                        <h3>Onboarding Process</h3>
                                        <p className='northernXDetails'>
                                            Upon sign-up, new accounts are greeted with a short onboarding presentation that iterates company policies.
                                        </p> 
                                        <p className='northernXDetails'>
                                            These policies are further re-iterated during the booking process and in a separate FAQ section in the app.
                                        </p>
                                    </Col>
                                    <Col md={8} className="northernXImgCol northernXImgCol1">
                                        <div>
                                            <img className="xscapeOnboarding xscapePrototype" src={XscapeOnboarding} alt="Xscape app's onboarding process is showcased on the payment page as well as on the FAQ page." />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className="northernXCol order-md-2">
                                        <h3>Comprehensive Product Overview</h3>
                                        <p className='northernXDetails'>
                                            Individual product pages include HD photos, and a summary of equipment features.
                                        </p> 
                                        <p className='northernXDetails'>
                                            Integrated functionalities allow users to seamlessly book the equipment, share the page, 
                                            and add items to their Wishlist directly from the equipment page.
                                        </p>
                                    </Col>
                                    <Col md={8} className="northernXImgCol northernXImgCol1 order-md-1">
                                        <div>
                                            <img className="xscapePorduct xscapePrototype" src={XscapeProduct} alt="Xscape app's product pages" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className="northernXCol">
                                        <h3>Map Search Function</h3>
                                        <p className='northernXDetails'>
                                            The map search function is also available on the product page. 
                                            Additional filters can be applied for further search customization. 
                                        </p> 
                                        <p className='northernXDetails'>
                                            This function allows users to navigate equipment rental based on the three rental locations.
                                        </p>
                                    </Col>
                                    <Col md={8} className="northernXImgCol northernXImgCol1">
                                        <div>
                                            <img className="xscapeMapSearch xscapePrototype" src={XscapeMapSearch} alt="The map search function is featured on the product page." />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container className='northernXProb'>
                                <Row>
                                    <Col md={12} className="northernXCol">
                                        <h2 className='sectionTitle'>Problem</h2>
                                        <p className='northernXDetails'>
                                            Northern Xscape Rental's existing website suffers from an antiquated design and a glitch-ridden booking system that frequently becomes overwhelmed, 
                                            leading to a subpar user experience that not only deters potential customers from booking with the company but also drives them toward competitors with more user-friendly interfaces. 
                                            The upcoming app serves as a much-needed modernization, overhauling their cumbersome rental process.
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                <h2 className='sectionTitle'>Define</h2>
                                    <Row>
                                        <Col md={7} className="northernXCol northernXDef">
                                            <p className='northernXDetails'>
                                                Replace glitchy online rental process
                                            </p>
                                        </Col>
                                        <Col md={5} className="northernXCol">
                                            <p className='northernXDetails northernXDef_p'>
                                                Enhance the rental process for customers, aid the company in ensuring that customers understand 
                                                rental requirements, and help with collection of data to improve user experience.
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={7} className="northernXCol northernXDef">
                                            <p className='northernXDetails'>
                                                Showcase company features
                                            </p>
                                        </Col>
                                        <Col md={5} className="northernXCol">
                                            <p className='northernXDetails northernXDef_p'>
                                                An opportunity to re-vamp and re-iterate company message/features in an attractive format 
                                                that is easily digestible, ultimately boosting company image.
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={7} className="northernXCol northernXDef">
                                            <p className='northernXDetails'>
                                                Stand out from competitors
                                            </p>
                                        </Col>
                                        <Col md={5} className="northernXCol">
                                            <p className='northernXDetails northernXDef_p'>
                                                With the prevalent use of smartphones, an app will aid in Northern Xscape’s differentiation from other rental
                                                companies that may not have apps.
                                            </p>
                                        </Col>
                                    </Row>
                            </Container>
                            <Container>
                                <h2 className='sectionTitle'>Research</h2>
                                <p className='northernXDetails'>
                                    External research was conducted to understand the competitive landscape. 
                                    The data collected was distilled to extract insights into competitive analysis, user persona, and a storyboard to create a distinct benchmark for app design.
                                </p>
                                <Row>
                                    <Col md={4} className="northernXCol">
                                        <h3>Competitive Analysis</h3>
                                        <p className='northernXDetails'>
                                            The competitive analysis revealed the following takeaways:
                                        </p>
                                        <p className='northernXDetails'>
                                            <li>Multiple search functions with filter options provide the most efficient user experience.</li>
                                            <li>Extensive outline of customer requirements that is readily available for reference.</li>
                                            <li>Slow navigation and booking process is a common pain point amongst consumers.</li>
                                        </p>
                                    </Col>
                                    <Col md={8} className="northernXCol">
                                        <img className="xscapeCompAnalysis xscapePrototype" src={XscapeCompAnalysis} alt="Competitive analysis for Northern Xscape against other equipment rental companies." />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className="northernXCol order-md-2">
                                        <h3>User Persona</h3>
                                        <p className='northernXDetails'>
                                            Based on user research, a persona was created to represent the market audience. 
                                        </p>
                                    </Col>
                                    <Col md={8} className="northernXCol order-md-1">
                                        <img className="xscapePersona xscapePrototype" src={XscapePersona} alt="User Persona for the mobile app based on target demographic." />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className="northernXCol">
                                        <h3>Storyboard</h3>
                                        <p className='northernXDetails'>
                                            To aid in visualization of the consumer rental process, a storyboard was designed.
                                        </p>
                                    </Col>
                                    <Col md={8} className="northernXCol">
                                        <img className="xscapeStory xscapePrototype" src={XscapeStoryboard} alt="Storyboard showcasing rental process from mobile app." />
                                    </Col>
                                </Row>
                            </Container>
                            <Container className="northernXProt">
                                <h2 className='sectionTitle'>Prototype</h2>
                                <div>
                                    <h3>Wireframes</h3>
                                        <img className="xscapeWireframes xscapePrototype" src={XscapeWireframes} alt="An overview of the low-fidelity wireframes for rental app." />
                                </div>
                                <Container className='xscapeHifi'>
                                    <h3>High-Fidelity Wireframes</h3>
                                        <img className="xscapeHifi xscapePrototype" src={XscapeHiFi} alt="Prototype of a few high-fidelity mobile app screens for Northern Xscape." />
                                </Container>
                                <Container className='xscapeDemo2'>
                                    <h3>Booking Process + Contact + FAQ + User Profile:</h3>
                                    <Row className='northernXDemo2Row'>
                                        <video className='northernXDemo' autoplay loop="true" controls width="100%">
                                            <source src={XscapeDemo2} title="Xscape app's booking process and mini-app tour demo" type="video/mp4" />
                                        </video>
                                    </Row>
                                </Container>
                                <Container className='xscapeConc'>
                                    <h2 className='sectionTitle'>Conclusion</h2>
                                    <p className='northernXDetails'>
                                        The introduction of a modernized digitial application for Northern Xscape Rentals replaced an outdated website leading to:
                                    </p>
                                    <p className='northernXDetails northernXDef_p'>
                                        <li>Strategic implementation of user-centric design that led to enhanced user experiences.</li>
                                        <li>Mitigation of issues that were steering potential customers away.</li>
                                        <li>Positioning that company as the preferred choice for adventure seekers.</li>
                                        <li>Successful integration of technology to elevate operational efficiency.</li>
                                    </p>
                                </Container>
                                <Container className='xscapeLogoRow'>
                                    <img className="xscapeLogo" src={XscapeLogo} alt="Northern Xscape Rental logo" />
                                </Container>
                            </Container>
                        </div>
            </ Collapsible>
            <br></br>
            {/* <hr className="lineBreak"/> */}
            <Collapsible trigger={["Citra Website", <FontAwesomeIcon icon={faChevronUp} className="projectChevron" alt="Reveal project"/>]}>
                            <div className="citraWeb">
                            <Container>
                                <Row>
                                    <h1>Citra Website</h1>
                                    <Col md={6}>
                                        <p>
                                            Designed a website for a fictional energy drink company called Citra.
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <p className="softwarePara">Sofware Used:</p>
                                        <div className="softwareIcons">
                                            <img src={xdIcon} alt="XD icon"/>
                                            <img src={illustratorIcon} alt="Illustrator icon"/>
                                            <img src={photoshopIcon} alt="Photoshop icon"/>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                <Row>
                                    <Col md={4} className="citrawebCol">
                                        <p className='citrawebDetails'>
                                            This is based on the Citra Brand Book. 
                                        </p>
                                    </Col>
                                    <Col md={8} className="citrawebImgCol">
                                        <div>
                                            <img className="citrawebCover" src={CitraWebCover} alt="Mock-up Website Cover of Citra" />
                                            <img className="citraWebLogo" src={CitraWebLogo} alt="Citra Logo" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container className="citrawebPages">
                                <Row>
                                    <Col md={12} className="citrawebCol">
                                        <h2>Prototype</h2>
                                        <p className='citrawebDetails'>
                                            See below for the final prototypes completed in Adobe XD:
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={7} >
                                        <h6>Home page</h6>
                                        <img className="citraHome citraPrototype" src={CitraHome} alt="Citra's home page" />
                                    </Col>
                                    <Col md={5}></Col>
                                </Row>
                                <Row>
                                    <Col md={5}></Col>
                                    <Col md={7}>
                                        <h6>Brand Usage</h6>
                                        <img className="citraBrand citraPrototype" src={CitraBrand} alt="Citra's brand usage page" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={7}>
                                        <h6>Colour Systems and Typography</h6>
                                        <img className="citraColour citraPrototype" src={CitraColour} alt="Citra's colour systems and typography page" />
                                    </Col>
                                    <Col md={5}></Col>
                                </Row>
                                <Row>
                                    <Col md={5}></Col>
                                    <Col md={7}>
                                        <h6>Shop</h6>
                                        <img className="citraShop citraPrototype" src={CitraShop} alt="Citra's shop page" />
                                    </Col>
                                </Row>
                            </Container>
                            <Container className="citraVideo">
                                <p className="citrawebDetails">Watch a demo below:</p>
                                <iframe 
                                width="100%" 
                                src="https://www.youtube.com/embed/yOOSo5QSLnU" 
                                title="Citra Website Demo video" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen>
                                </iframe>
                            </Container>
                            </div>
            </ Collapsible>
        </div>
        <ScrollTop />
        </div>
    )
}

export default UIUX; 
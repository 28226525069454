import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Collapsible from 'react-collapsible'

// Components
// import ProjectsMenu from '../../components/ProjectsMenu';
import ScrollTop from '../../components/ScrollTop';

// Stylesheet
import '../../css/projects.css'

// Software Icons
import mayaIcon from '../../images/icons/mayaIcon.png';
import mudboxIcon from '../../images/icons/mudboxIcon.png';
import painterIcon from '../../images/icons/painterIcon.png';
import stagerIcon from '../../images/icons/stagerIcon.png';

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

// Diorama Project Images
import dioramaWF from '../../images/3Dprojects/dioramaWF.png';
import diorama from '../../images/3Dprojects/diorama.jpg';

// Breakfast Scene Project Images
import bfastSceneWF from '../../images/3Dprojects/BreakfastSceneWF.png';
import bfastScene from '../../images/3Dprojects/BreakfastScene.png';

function ThreeD() {
    return (
        <div>
            {/* <ProjectsMenu /> */}
            <div className='ThreeD'>
                <Collapsible trigger={["Diorama", <FontAwesomeIcon icon={faChevronUp} className="projectChevron" alt="Reveal project"/>]}>
                    <div className="diorama">
                    <Container>
                        <Row>
                            <h1>Explorer 3D Game Kit Asset Diorama</h1>
                            <Col md={6}>
                                <p>
                                    Assembled a 3D diorama from assets created to emulate gaming elements from Unity’s Explorer 3D Game Kit.
                                </p>
                            </Col>
                            <Col md={6}>
                                <p className="softwarePara">Sofware Used:</p>
                                <div className="softwareIcons">
                                    <img src={mayaIcon} alt="Maya icon"/>
                                    <img src={mudboxIcon} alt="Mudbox icon"/>
                                    <img src={painterIcon} alt="Substance 3D Painter icon"/>
                                    <img src={stagerIcon} alt="Substance 3D Stager icon"/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col md={4} className="dioramaCol">
                                <p className='dioramaDetails'>
                                    After modeling in Maya and adding texture with Mudbox, the assets were then painted in Substance 3D Painter 
                                    and compiled in Substance 3D Stager to create the diorama:
                                </p>
                            </Col>
                            <Col md={8} className="dioramaImgCol">
                                <img className="dioramaWFImg" src={dioramaWF} alt="Diorama assets in substance 3D Painter" />
                            </Col>
                        </Row>
                    </Container>
                    <Container className="dioramaCont">
                        <img className="dioramaImg" src={diorama} alt="Final diorama" />
                    </Container>
                    </div>
                </ Collapsible>
                <br></br>
                {/* <hr className="lineBreak"/> */}
                <Collapsible trigger={["Breakfast Scene", <FontAwesomeIcon icon={faChevronUp} className="projectChevron" alt="Reveal project"/>]} >
                    <div className="breakfastScene">
                    <Container>
                        <Row>
                            <h1>Breakfast Scene</h1>
                            <Col md={6}>
                                <p>
                                    Modelled a 3D breakfast scene from everyone’s favourite worst boss, Michael Scott (from “The Office”), 
                                    with references to specific scenes from the show.
                                </p>
                            </Col>
                            <Col md={6}>
                                <p className="softwarePara">Sofware Used:</p>
                                <div className="softwareIcons">
                                    <img src={mayaIcon} alt="Maya icon"/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col md={4} className="bfastCol">
                                <p className='bfastDetails'>
                                    Below is a closer look at the 3D modelling of the scene with wireframe view before the final render:
                                </p>
                            </Col>
                            <Col md={8} className="bfastImgCol">
                                <img className="bfastWFImg" src={bfastSceneWF} alt="Diorama assets in substance 3D Painter" />
                            </Col>
                        </Row>
                    </Container>
                    <Container className="bfastCont">
                        <img className="bfastImg" src={bfastScene} alt="Final Breakfast Scene Render" />
                    </Container>
                    </div>
                </ Collapsible>
            </div>
            <ScrollTop />
        </div>
    );
}

export default ThreeD; 
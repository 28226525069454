import React, { useState } from 'react'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import { send } from 'emailjs-com';

import contactBG from '../images/contactBG.png';
import contactPhoto from '../images/ContactPhoto.png';

function Contact () {
  const [toSend, setToSend] = useState ({
    from_name: '',
    reply_to: '',
    message: '',
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send(
      'service_w3c22qg',
      'template_gb1fyph',
      toSend,
      'RIcJ4P5FFpO-eRs8D'
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert("Your message was sent successfully.");
      })
      .catch((err) => {
        console.log('FAILED...', err);
        alert("Error in sending your message.")
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className='contact' style={{ backgroundImage: `url(${contactBG})`, backgroundSize:"cover" }}>
      <Container>
        <Row>
          <Col md={7} className="contactForm">
            <h2 className='contacthead'>Get in Touch</h2>
            <Form onSubmit={onSubmit}>
              <Form.Group className="form-outline mb-4">
                <Form.Control
                  type='text'
                  name='from_name'
                  placeholder='Your Name*'
                  required
                  value={toSend.from_name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="form-outline mb-4">
              <Form.Control
                type='email'
                name='reply_to'
                placeholder='Email Address*'
                required
                value={toSend.reply_to}
                onChange={handleChange}
              />
              </Form.Group>
              <Form.Group className="form-outline mb-4">
              <textarea
                className="form-control"
                rows="4"
                type='text'
                name='message'
                placeholder='Your Message*'
                required
                value={toSend.message}
                onChange={handleChange}
              />
              </Form.Group>
              <p align="right">*All fields are required for submission.</p>
              <Button type='submit' className="submitbtn">Send</Button>
            </Form>
          </Col>
          <Col md={5} className="contactphotoCol">
            <img className="contactPhoto" src={contactPhoto} alt="me smiling at the Ontario Science Center"></img>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
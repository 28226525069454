import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Collapsible from 'react-collapsible'

// Components
// import ProjectsMenu from '../../components/ProjectsMenu';
import ScrollTop from '../../components/ScrollTop';

// Stylesheet
import '../../css/projects.css'

// Software Icons
import illustratorIcon from '../../images/icons/illustratorIcon.png';
import aftereffectsIcon from '../../images/icons/aftereffectsIcon.png';

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

// Thee League Intro Project Images
import theeLeagueInfo from '../../images/motiongraphicprojects/TheeLeagueInfo2.png';

// Breakfast Scene Project Images
import theOfficeInfo from '../../images/motiongraphicprojects/TheOfficeInfo2.png';

function MotionGraphic() {
    return (
        <div>
            {/* <ProjectsMenu /> */}
            <div className='MotionGraphic'>
                <Collapsible trigger={["Thee League", <FontAwesomeIcon icon={faChevronUp} className="projectChevron" alt="Reveal project"/>]} >
                        <div className="theeLeague">
                        <Container>
                            <Row>
                                <h1>Thee League Intro</h1>
                                <Col md={6}>
                                    <p>
                                        Animated an intro for a local basketball league.
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <p className="softwarePara">Sofware Used:</p>
                                    <div className="softwareIcons">
                                        <img src={illustratorIcon} alt="Illustrator icon"/>
                                        <img src={aftereffectsIcon} alt="AfterEffects icon"/>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <Col md={4} className="theeLeagueCol">
                                    <p className='theeLeagueDetails'>
                                        I began by sketching out a very basic outline of the scenes I wanted to include, and then illustrating
                                        the sketches in Adobe Illustrator.
                                    </p>
                                </Col>
                                <Col md={8} className="theeLeagueImgCol">
                                    <img className="theeLeagueInfoImg" src={theeLeagueInfo} alt="Hand-drawn sketches and Illustrator design for animated intro scenes" />
                                </Col>
                            </Row>
                        </Container>
                        <Container className="theeLeagueVidCont">
                            <p className="theeLeagueDetails">Watch the video below:</p>
                            <iframe 
                                width="100%" 
                                // height="315" 
                                src="https://www.youtube.com/embed/pMGMHOEyTtk" 
                                title="Thee League animation video" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen>
                            </iframe>
                        </Container>
                        </div>
                </ Collapsible>
                <br></br>
                {/* <hr className="lineBreak"/> */}
                <Collapsible trigger={['The Office', <FontAwesomeIcon icon={faChevronUp} className="projectChevron" alt="Reveal project"/>]}>
                    <div className="theOffice">
                    <Container>
                        <Row>
                            <h1>The Office - "Stress Relief" - Kinetic Motion Animation</h1>
                            <Col md={6}>
                                <p>
                                    Animated a cold open of an episode of The Office entitled “Stress Relief” (Season 5, Episode 14). 
                                    <span> Can you tell I like The Office?</span>
                                </p>
                            </Col>
                            <Col md={6}>
                                <p className="softwarePara">Sofware Used:</p>
                                <div className="softwareIcons">
                                    <img src={illustratorIcon} alt="Illustrator icon"/>
                                    <img src={aftereffectsIcon} alt="AfterEffects icon"/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col md={4} className="theOfficeCol">
                                <p className='theOfficeDetails'>
                                    Pre-production was done in Milanote including a moodboard as well as a hand-sketched story board.
                                </p>
                            </Col>
                            <Col md={8} className="theOfficeImgCol">
                                <img className="theOfficeImg" src={theOfficeInfo} alt="The Office animation pre-production in Milanote" />
                            </Col>
                        </Row>
                    </Container>
                    <Container className="theOfficeCont">
                        <p className="theOfficeDetails">Watch the video below:</p>
                        <iframe 
                            width="100%"
                            src="https://www.youtube.com/embed/uHpK4TkE2e0" 
                            title="The office - Kinetic Motion Animation" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen>
                        </iframe>
                    </Container>
                    </div>
                </ Collapsible>
            </div>
            <ScrollTop />
        </div>
    );
}

export default MotionGraphic; 
import React from 'react'
import { NavLink } from "react-router-dom";

import { Container, Row, Col, Button } from 'react-bootstrap'
import Typewriter from 'typewriter-effect'

// Images
import aboutImg1 from '../images/aboutme/aboutme_img1.png';
import aboutImg2 from '../images/aboutme/aboutme_img2.png';
import aboutImg3 from '../images/aboutme/aboutme_img3.png';

// Component
import ScrollTop from '../components/ScrollTop';


function About() {
    return (
        <div className='about'>
            <Container>
                <Row className='aboutRow1'>
                    <Col md={7} className="aboutCol1">
                        <h1 className='aboutmeTitle'>About Me</h1>
                        <h2>Hey, what's up?</h2>
                        <p className='aboutpara aboutpara1'>
                            I'm Naisha. <br></br>
                            <br></br>
                            I wish I had a cool backstory to my name but I don’t. 
                            My dad claims he had “nothing” for my name, and with a quick line of thinking thought of “Naisha” from “nothing”. 
                            Although the optics of sharing this backstory is not the best, 
                            I think of it as how I also create something out of “nothing” as a designer.
                        </p>
                        <p className="nothing"><Typewriter
                            options={{
                                autoStart: true,
                                loop: true,
                                deleteSpeed: 25,
                            }}
                            onInit={(typewriter) => {
                                typewriter.typeString('Nothing')
                                .pauseFor(1000)
                                .deleteChars(6)
                                .typeString('aisha')
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString('Something!')
                                .pauseFor(1500)
                                .start();
                            }}
                            />
                        </p>
                    </Col>
                    <Col md={5} className="aboutImgCol">
                        <img className="aboutImg aboutImg1" src={aboutImg1} alt="me at the top of Montserrat"/>
                        <p>Me at the top of Montserrat, a 1.5-hour hike (and I thought the font was already pretty!).</p>
                    </Col>
                </Row>
                <Row className='aboutRow2'>
                    <Col md={7} className="aboutCol2 order-md-2">
                        <p className='aboutpara aboutpara2'>
                            Moving forward from my slightly disappointing namesake, 
                            I have a cooler birthplace of Japan.<br></br> 
                            <br></br>
                            Born and raised in Japan before moving to Ontario in 2004, 
                            I learned discipline, and an appreciation for clean and minimalistic, but meaningful design.<br></br>
                            <br></br>
                            Also the place where I solidified my life-long dedication to the peace sign.
                        </p>
                    </Col>
                    <Col md={5} className="aboutImgCol order-md-1">
                        <img className="aboutImg aboutImg2" src={aboutImg2} alt="me as a child posing with a peace sign"/>
                        <p>When my friend saw this picture she said, "you have not changed."</p>
                    </Col>
                </Row>
                <Row className='aboutRow3'>
                    <Col md={7} className="aboutCol3">
                        <p className='aboutpara aboutpara3'>
                            In Ontario, I pursued an undergraduate degree in biochemistry. 
                            In science, I found a surprising passion for nature's innate ability to create its own patterns, 
                            and honed my practical and analytical skills.<br></br> 
                            <br></br>
                            Being part of the co-op program also exposed me to multiple industries:<br></br>
                            <br></br>
                                <li>Food technologist at Maple Leaf Foods</li>
                                <li>Milling laboratory assistant at Vive Crop Protection</li>
                                <li>R&D co-op student at Wholesome Harvest Baking</li>
                        <br></br>
                        See my resume below for further information:
                        </p>
                        <Button
                            onClick={() => {
                            window.open("https://drive.google.com/file/d/1ESWB9DBYQWQnCPCVnuHWetAe5iPVfXIK/view?usp=share_link");
                            }}
                            className='resumebtn'><span>Resume</span>
                        </Button>
                    </Col>
                    <Col md={5} className="aboutImgCol aboutImgCol3">
                        <img className="aboutImg aboutImg3" src={aboutImg3} alt="diagram depicting the multiple industries I worked in"/>
                    </Col>
                </Row>
                <Row className='aboutRow4'>
                    <Col md={6} className="aboutCol4">
                        <p className="aboutpara aboutpara4">
                            These co-op experiences allowed me the opportunity to pursue other careers in science 
                            where I was able to further my learning.
                            <br></br><br></br>
                            Watch me work below! (Starting at 1:08):
                        </p>
                        <Container className="viroxVideo">
                            <iframe 
                            width="100%"
                            src="https://www.youtube.com/embed/9jinz0ZBhXI?si=HajBJno7YBCRWLiH&amp;start=68" 
                            title="Wall Street Journal profile on Virox Technologies" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen>
                            </iframe>
                        </Container>
                    </Col>
                    <Col md={6} className="aboutImg4">
                        <div>
                            <p className="aboutpara aboutpara5">
                                This composite of experiences in tandem with my playful style, 
                                produced a perpetual student of creativity and inspiration. 
                                Whether through fashion or building wireframes, design is something that I enjoy creating as well as experiencing.
                            </p>
                        </div>
                        <br></br>
                        <div>
                            <NavLink to="/Projects">
                                <Button className="projectsbtn">Click here to view my Projects</Button>
                            </NavLink>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ScrollTop />
        </div>
    )
}

export default About
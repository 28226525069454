import React from 'react';
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';

// Components
import Text from '../components/HomeText';

// Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPeace } from '@fortawesome/free-regular-svg-icons';
import { faUser, faBriefcase, faMessage } from '@fortawesome/free-solid-svg-icons';

// Images
import selfie from '../images/selfie2.png';
  
function Home() {
    return (
      <div className='homepagebackground'>
        <Container>
          <Row>
            <Col md={5} className="hometext">
              <h2 className='headtext'>Hello <span className='peace'><FontAwesomeIcon icon={faHandPeace} aria-label='animated peace sign icon'/></span></h2>
              <h2 className='nametext1'>I'm <span className='nametext2'>Naisha Ho</span></h2>
              <span></span>
              <div className ='typewriter'><Text/></div>
              <div className="navbtns">
                <NavLink to='/About'>
                  <button className="navbtn"><FontAwesomeIcon className="icon" icon={faUser} /><p>Learn About Me</p></button>
                </NavLink>
                <NavLink to='/Projects'>
                  <button className="navbtn"><FontAwesomeIcon className="icon" icon={faBriefcase} /><p>View My Projects</p></button>
                </NavLink>
                <NavLink to='/Contact'>
                  <button className="navbtn"><FontAwesomeIcon className="icon" icon={faMessage} /><p>Let's Talk!</p></button>
                </NavLink>
              </div>
            </Col>
            <Col md={7}>
              <img className="selfie" src={selfie} alt="WPAP self-portrait" />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  
export default Home
import * as React from "react";
import { Routes, Route } from "react-router-dom";

// Navigation
import Home from './assets/pages/Home';
import About from './assets/pages/About';
import Projects from './assets/pages/Projects';
import Contact from './assets/pages/Contact';
import NoMatch from './assets/pages/NoMatch';
import Header from './assets/components/Header';

// Project Categories
import ThreeD from './assets/pages/projects/3D';
import GraphicDesign from './assets/pages/projects/GraphicDesign';
import MotionGraphic from './assets/pages/projects/MotionGraphic';
import UIUX from './assets/pages/projects/UIUX';
import OtherWorks from './assets/pages/projects/OtherWorks';

import TransitionComponent from './assets/components/Transition';
import ProjectsMenu from "./assets/components/ProjectsMenu";
// import ProjectTransition from "./assets/components/ProjectTransition";

// With /projects/.. and no transition to projects

export default function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<TransitionComponent><Home /></TransitionComponent>} />
        <Route path="/about" element={<TransitionComponent><About /></TransitionComponent>} />
        <Route path="/contact" element={<TransitionComponent><Contact /></TransitionComponent>} />
        <Route path="/projects" element={<TransitionComponent><ProjectsMenu /></TransitionComponent>} >
          <Route index element={<TransitionComponent><Projects /></TransitionComponent>} />
          <Route path="3D" element={<TransitionComponent><ThreeD /></TransitionComponent>} />
          <Route path="graphicDesign" element={<TransitionComponent><GraphicDesign /></TransitionComponent>} />
          <Route path="motionGraphic" element={<TransitionComponent><MotionGraphic /></TransitionComponent>} />
          <Route path="uiux" element={<TransitionComponent><UIUX /></TransitionComponent>} />
          <Route path="otherWorks" element={<TransitionComponent><OtherWorks /></TransitionComponent>} />
        </Route>
        <Route path="*" element={<TransitionComponent><NoMatch /></TransitionComponent>} />
      </Routes>
    </div>
  );
}

// No TransitionComponent

// export default function App() {

//   return (
//     <div>
//       <Header />
//         <Routes>
//           <Route path="/" element={<TransitionComponent><Home /></TransitionComponent>} />
//           <Route path="/about" element={<TransitionComponent><About /></TransitionComponent>} />
//           <Route path="/contact" element={<TransitionComponent><Contact /></TransitionComponent>} />
//           <Route path="/projects" element={<TransitionComponent><ProjectsMenu /></TransitionComponent>} >
//             <Route index element={<Projects />} />
//             <Route path="3D" element={<ThreeD />} />
//             <Route path="graphicDesign" element={<GraphicDesign />} />
//             <Route path="motionGraphic" element={<MotionGraphic />} />
//             <Route path="uiux" element={<UIUX />} />
//             <Route path="otherWorks" element={<OtherWorks />} />
//           </Route>
//           <Route path="*" element={<TransitionComponent><NoMatch /></TransitionComponent>} />
//         </Routes>
//     </div>
//   );
// }


// With /projects/..

// export default function App() {
//   return (
//     <div>
//       <Header />
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/about" element={<TransitionComponent><About /></TransitionComponent>} />
//         <Route path="/contact" element={<TransitionComponent><Contact /></TransitionComponent>} />
//         <Route path="/projects" element={<TransitionComponent><ProjectsMenu /></TransitionComponent>} >
//           <Route index element={<TransitionComponent><Projects /></TransitionComponent>} />
//           <Route path="3D" element={<TransitionComponent><ThreeD /></TransitionComponent>} />
//           <Route path="graphicDesign" element={<TransitionComponent><GraphicDesign /></TransitionComponent>} />
//           <Route path="motionGraphic" element={<TransitionComponent><MotionGraphic /></TransitionComponent>} />
//           <Route path="uiux" element={<TransitionComponent><UIUX /></TransitionComponent>} />
//           <Route path="otherWorks" element={<TransitionComponent><OtherWorks /></TransitionComponent>} />
//         </Route>
//         <Route path="*" element={<TransitionComponent><NoMatch /></TransitionComponent>} />
//       </Routes>
//     </div>
//   );
// }


// Original

// export default function App() {
//   return (
//     <div>
//       <Routes>
//         <Route path="/" element={<Header />}>
//           <Route index element={<TransitionComponent><Home /></TransitionComponent>} />
//           <Route path="/about" element={<TransitionComponent><About /></TransitionComponent>} />
//           <Route path="/contact" element={<TransitionComponent><Contact /></TransitionComponent>} />
//           <Route path="*" element={<TransitionComponent><NoMatch /></TransitionComponent>} />

//           {/* Project Categories */}
//           <Route path="/projects" element={<TransitionComponent><Projects /></TransitionComponent>} />
//           <Route path="/3D" element={<TransitionComponent><ThreeD /></TransitionComponent>} />
//           <Route path="/graphicDesign" element={<TransitionComponent><GraphicDesign /></TransitionComponent>} />
//           <Route path="/motionGraphic" element={<TransitionComponent><MotionGraphic /></TransitionComponent>} />
//           <Route path="/uiux" element={<TransitionComponent><UIUX /></TransitionComponent>} />
//           <Route path="otherWorks" element={<TransitionComponent><OtherWorks /></TransitionComponent>} />
//         </Route>
//       </Routes>
//     </div>
//   );
// }
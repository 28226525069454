import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, Outlet } from "react-router-dom";
import logo from '../images/logo.png';

const Header = () => {
  return (
    <div>
      <div>
        <Navbar className="navbar" fixed="top" expand="lg">
          <div className="nav">
              <Navbar.Brand className='logotext' to='/'>
                <NavLink to="/"><img className="logo" src={logo} alt="logo/home_button" /></NavLink>
                <span className="headerName">Naisha H.</span>
              </Navbar.Brand>
              <Navbar.Toggle className='navbar-toggler' aria-controls="basic-navbar-nav" ></Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav" className='responsive-navbar' >
                <Nav className="ms-auto links">
                  <Nav.Item>
                    <NavLink to="/">Home</NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="/about">About Me</NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="/projects">Projects</NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink to="/contact">Contact</NavLink>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
      
      <div><Outlet /></div>
    </div>
  );
}

export default Header;
import React from 'react';

import Nav from 'react-bootstrap/Nav';
import { NavLink, Outlet, useResolvedPath } from "react-router-dom";

const ProjectsMenu = () => {

  const url = useResolvedPath("").pathname;
  console.log("topics rendered");

  return (
    <div className="projectsMenu1">
      <h1 className="projectsMenuHeader"> Projects </h1>
      <div className="projectsMenu">
          <Nav className="ms-auto links projectsNav">
            <Nav.Item>
              <NavLink to={`${url}/3D`}>3D</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`${url}/graphicDesign`}>Graphic Design</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`${url}/motionGraphic`}>Motion Graphic</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`${url}/uiux`}>UI/UX</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`${url}/otherWorks`}>Other Works</NavLink>
            </Nav.Item>
          </Nav>
      </div>
      
        <div><Outlet /></div>
    </div>
  );
}

export default ProjectsMenu;


// const ProjectsMenu = () => {
//   return (
//     <div className="projectsMenu1">
//       <h1 className="projectsMenuHeader"> Projects </h1>
//       <div className="projectsMenu">
//           <Nav className="ms-auto links projectsNav">
//             <Nav.Item>
//               <NavLink to="/projects/3D">3D</NavLink>
//             </Nav.Item>
//             <Nav.Item>
//               <NavLink to="/projects/graphicDesign">Graphic Design</NavLink>
//             </Nav.Item>
//             <Nav.Item>
//               <NavLink to="/projects/motionGraphic">Motion Graphic</NavLink>
//             </Nav.Item>
//             <Nav.Item>
//               <NavLink to="/projects/uiux">UI/UX</NavLink>
//             </Nav.Item>
//             <Nav.Item>
//               <NavLink to="/projects/otherWorks">Other Works</NavLink>
//             </Nav.Item>
//           </Nav>
//       </div>
      

//       <div><Outlet /></div>

//     </div>
//   );
// }

// export default ProjectsMenu;
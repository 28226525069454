import React from "react";

// import ProjectsMenu from "../components/ProjectsMenu";

function Projects() {
  return (
    <div className="projects">

        <div>
          <p>Click the categories above to see my work!</p>
        </div>
    </div>
  );
}

export default Projects;


import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Collapsible from 'react-collapsible'

// Components
// import ProjectsMenu from '../../components/ProjectsMenu';
import ScrollTop from '../../components/ScrollTop';

// Stylesheet
import '../../css/projects.css'

// Software Icons
import illustratorIcon from '../../images/icons/illustratorIcon.png';
import dimensionsIcon from '../../images/icons/dimensionsIcon.png';
import indesignIcon from '../../images/icons/indesignIcon.png';

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

// Citra Brand Book Project Images
import CitraCover from '../../images/graphicdesignprojects/CitraBookCover.png';
import CitraMockup from '../../images/graphicdesignprojects/CitraBookMockUp.png';
import CitraOverview from '../../images/graphicdesignprojects/Citra_overview.png';
import CitraUsage from '../../images/graphicdesignprojects/Citra_brandUsage.png';
import CitraLogo from '../../images/graphicdesignprojects/Citra_logo.png';
import CitraMood from '../../images/graphicdesignprojects/Citra_moodboard.png';
import CitraTypo from '../../images/graphicdesignprojects/Citra_typo.png';
import CitraApp from '../../images/graphicdesignprojects/Citra_brandApp.png';

// 7 Wonders Icons Project Images
import WondersCover from '../../images/graphicdesignprojects/7WondersCover.png';
import WondersMockup from '../../images/graphicdesignprojects/7WondersMockUp.png';
import WondersMap from '../../images/graphicdesignprojects/7Wonders_mindmap.png';
import WondersColour from '../../images/graphicdesignprojects/7Wonders_colour.png';
import WondersRatio from '../../images/graphicdesignprojects/7Wonders_goldenRatio.png';
import WondersIcons from '../../images/graphicdesignprojects/7Wonders_icons.png';

function GraphicDesign() {
    return (
        <div>
        {/* <ProjectsMenu /> */}
        <div className='GraphicDesign'>
            <Collapsible trigger={["Citra Brand Book", <FontAwesomeIcon icon={faChevronUp} className="projectChevron" alt="Reveal project"/>]} >
                            <div className="citraBook">
                            <Container>
                                <Row>
                                    <h1>Citra Brand Book</h1>
                                    <Col md={6}>
                                        <p>
                                            Created a brand book for a fictional energy drink company called Citra.
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <p className="softwarePara">Sofware Used:</p>
                                        <div className="softwareIcons">
                                            <img src={illustratorIcon} alt="Illustrator icon"/>
                                            <img src={dimensionsIcon} alt="Dimensions icon"/>
                                            <img src={indesignIcon} alt="InDesign icon"/>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                <Row>
                                    <Col md={4} className="citraCol">
                                        <p className='citraDetails'>
                                            The logo for the Citra brand was initially created in Illustrator and the brand book was put together in InDesign.
                                        </p>
                                    </Col>
                                    <Col md={8} className="citraImgCol">
                                        <div>
                                            <img className="citraCoverMock" src={CitraCover} alt="Mock-up Cover of Citra Brand Book" />
                                            <img className="citraBookMock" src={CitraMockup} alt="Mock-up of Citra Brand Book" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container className="citraPages">
                                <Row>
                                    <Col md={7} >
                                        <img className="citraOverview" src={CitraOverview} alt="Citra Brand book Overview" />
                                    </Col>
                                    <Col md={5}></Col>
                                </Row>
                                <Row>
                                    <Col md={5}></Col>
                                    <Col md={7}>
                                        <img className="citraUsage" src={CitraUsage} alt="Citra Brand book Brand Usage" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={7}>
                                        <img className="citraLogo" src={CitraLogo} alt="Citra Brand book Logo" />
                                    </Col>
                                    <Col md={5}></Col>
                                </Row>
                                <Row>
                                    <Col md={5}></Col>
                                    <Col md={7}>
                                        <img className="citraMood" src={CitraMood} alt="Citra Brand book Colour Palette & Moodboard " />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={7}>
                                        <img className="citraTypo" src={CitraTypo} alt="Citra Brand book Typography" />
                                    </Col>
                                    <Col md={5}></Col>
                                </Row>
                                <Row>
                                    <Col md={5}></Col>
                                    <Col md={7}>
                                        <img className="citraApp" src={CitraApp} alt="Citra Brand book Brand Applications " />
                                    </Col>
                                </Row>
                            </Container>
                            </div>
            </ Collapsible>
            <br></br>
            {/* <hr className="lineBreak" /> */}
            <Collapsible trigger={["7 Wonders of the World", <FontAwesomeIcon icon={faChevronUp} className="projectChevron" alt="Reveal project"/>]}>
                            <div className="wondersBook">
                            <Container>
                                <Row>
                                    <h1>7 Wonders of the World Icons Brand Book</h1>
                                    <Col md={6}>
                                        <p>
                                            Designed a set of 6 icons featuring the 7 Wonders of the World utilizing only the golden circles.
                                        </p>
                                    </Col>
                                    <Col md={6}>
                                        <p className="softwarePara">Sofware Used:</p>
                                        <div className="softwareIcons">
                                            <img src={illustratorIcon} alt="Illustrator icon"/>
                                            <img src={indesignIcon} alt="InDesign icon"/>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                <Row>
                                    <Col md={4} className="wondersCol">
                                        <p className='wondersDetails'>
                                            The following icons were first sketched out by hand. The sketches were then brought into Illustrator
                                            and conformed to fit the golden circle ratios.
                                        </p>
                                    </Col>
                                    <Col md={8} className="wondersImgCol">
                                        <div>
                                            <img className="wondersCoverMock" src={WondersCover} alt="Mock-up Cover of 7 Wonders Icons Brand Book" />
                                            <img className="wondersBookMock" src={WondersMockup} alt="Mock-up of 7 Wonders Icons Brand Book" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container className="wondersPages">
                                <Row>
                                    <Col md={12} >
                                        <img className="wondersMap" src={WondersMap} alt="7 Wonders Icons Brand book Overview" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}></Col>
                                    <Col md={8}>
                                        <img className="wondersColour" src={WondersColour} alt="7 Wonders Icons Brand book Colour Palette" />
                                    </Col>
                                    <Col mc={2}></Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <img className="wondersRatio" src={WondersRatio} alt="7 Wonders Icons Brand book Golden Ratios" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}></Col>
                                    <Col md={6}>
                                        <img className="wondersIcons" src={WondersIcons} alt="7 Wonders Icons Brand book Final Icons" />
                                    </Col>
                                    <Col md={3}></Col>
                                </Row>
                            </Container>
                            </div>
            </ Collapsible>
        </div>
        <ScrollTop />
        </div>
    )
}

export default GraphicDesign; 
import React from 'react'
import Typewriter from "typewriter-effect";

function Text() {
  return (
    <Typewriter
      options={{
        strings: [
          'Interactive Designer\nWith a Biochemistry background',
        ],
        autoStart: true,
        loop: true,
        delay: 25,
        deleteSpeed: 25,
        pauseFor: 1500
      }}
    />
  )
}

export default Text